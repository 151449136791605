.notificationWrapperContainer {
  position:fixed;
  right:0;
  top: 0;
  padding:1em;
}

.notificationContainer {
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  animation: SlideLeft 0.5s;
  animation-fill-mode: forwards;
  width: 100%;
}

.notificationContainer.exit{
  animation: SlideRight 0.5s;
  animation-fill-mode: forwards;
}
.bar {
  height: 3px;
}
.bar.failure {
  background-color: tomato;
}

.bar.success {
  background-color: lightgreen;
}

.notificationContainer p {
  margin: 0;
  padding: 0.6rem;
}
@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 1200;
  }
  100% {
    margin-left: 120%;
  }
}
