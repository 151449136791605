
$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: rgba(31, 40, 51, 1);


@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ 

    .signUpContainer{
        width: 75%;
        margin: 0 auto;
        margin-top: 10vh;
  
       
    }
    
    .signUpForm{
        display: flex;
        flex-direction: column;
        padding: 0.7rem;
        border:10px solid #0b0c10;
        background-color: #c5c6c7;
        box-shadow:  0 5px 15px 0 rgba(0, 0, 0, 0.5);
        border-radius: 5px;
      

    }
    .signUpForm h4{
        text-align: center;
        margin-bottom:0.8em;
        margin-top: 1em;
        font-size: 5vh;
    }

    .inputContainer{
        overflow: hidden;
        margin-bottom: 2vh;

    }
    .formField {
        width: 100%;
        border: 0;
        border-bottom: 4px solid $gray;
        outline: 0;
        background: transparent;
        transition: border-color 0.2s;
    }
    
    .formLabel { 
        font-size: 2.5vh;
        color: $gray;
        font-weight: bold;
    }
    
    .formField:focus {
        border-bottom: 4px solid rgba(102, 252, 242, 0.507);
    }
    
    .signButtonContainer{
        padding:0.6em;
        border:1em;
        display: grid;
        justify-items: center;
        align-items:  center;
    }
    .signButtonContainer button{
        width: 10em;
        height: 2em;
        background-color: #0b0c10;
        color:rgb(102, 252, 241);
        border-radius: 25px;
        font-size: 2.5vh;
        font-weight: bold;
        
    }

    .loading{
        width:6em;
        height:10em;
    }

    input, select { font-size: 25px; }

}
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 

}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 

    .signUpContainer{
        width: 30em;      
    }
}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 

    .signUpContainer{
        width: 35em;      
    }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 

    .signUpContainer{
        width: 40em;       
    }

}
@media (min-width:1281px) { /* hi-res laptops and desktops */ 



}