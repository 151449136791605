.homeContainer {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1.2fr 11fr 1fr;
    grid-template-areas:
        "header header header"
        ". . signout"
        "leftbar mainCard rightbar"
        "footer footer footer";
    gap: 1rem;
    height: 90vh;
    padding: 1rem;
}

.mainCard {
    grid-area: mainCard;
    display: grid;
    grid-template-columns: 1fr 25rem 1fr;
    grid-template-areas: "previous card next";
    gap:6px;
}

.previous {
    grid-area: previous;

}

.next {
    grid-area: next;

}

.next,
.previous {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {

    grid-area: card;
    padding: 0.8rem;
    border-radius: 5px;
    box-shadow: 0 5px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #0b0c10;
    width: auto;
    height: fit-content;
}

.rightbar {
    grid-area: rightbar;
}

.leftbar {
    grid-area: leftbar;
}

.header {
    grid-area: header;
    text-align: center;
    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-template-areas: "title";
}

.footer {
    grid-area: footer;
    text-align: center;
}

.signout {
    grid-area: signout;
    justify-self: right;
}

.navigationButton {
    height: 8em;
    width: 8em;
    border-radius: 25%;
}
.navigationButton:hover {
    box-shadow: 0 0 5px 0 var(--blue-highlight);
    cursor: pointer;
}

@media (max-width: 1000px) {
    .homeContainer {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr 10fr;
        grid-template-areas:
            "signout"
            "mainCard";
        gap: 0;
        height: 90vh;
        padding: 0;
    }
    .header,
    .footer,
    .leftbar,
    .rightbar {
        display: none;
    }
    .signout {
        grid-area: signout;
        justify-self: center;
    }
    .mainCard {
        grid-area: mainCard;
        display: grid;
        grid-template-columns:15vw 40vw 15vw;
        grid-template-areas: "previous card next";
        gap: 3px;
        justify-content: center;
    }
    .card {
        border: none;
        grid-area: card;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 0 5px 25px 0px rgba(0, 0, 0, 0.25);
        background-color: #0b0c10;
        height: 90vh;
        width: auto;

    }

    .navigationButton {
        height: 3rem;
        width: 3rem;
        border-radius: 25%;
    }
    .previous, .next{
        border:0;
        padding: 0;
  
    }
}
@media(max-width:900px){
    .mainCard{
        grid-template-columns:12.5vw 50vw 12.5vw;
    }
}
@media(max-width:850px){
    .mainCard{
        grid-template-columns:10vw 60vw 10vw;
    }
}
 @media (max-width: 400px) and ( max-height:900px){

    .mainCard{
         gap:3px;

     }
 }

 