.checkboxContainer {
}
.individualCheckbox {
    display: inline-block;
}
.dataEditForm {
    display: grid;
    justify-items: center;
    gap: 0.5rem;
}

 
$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: rgba(31, 40, 51, 1);

.form__field {
    width: 100%;
    border: 0;
    padding-bottom: 0.3rem;
    border-bottom: 2px solid $gray;
    outline: 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form__label { 
    font-size: 1rem;
    color: $gray;
    font-weight: bold;
}

.form__field:focus {
    border-bottom: 2px solid rgba(102, 252, 242, 0.507);
    background: transparent;
}

.form__display {
    margin: 0;
}
.form__viewList{
  padding:0;
  margin:0;
}

.form__viewItem{
  padding-bottom: 4px;

}