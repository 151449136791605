.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    background-color: #0b0c10;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  .nav-menu {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
  }
  .navbar-logo {
    color: rgb(102, 252, 241);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }

  .nav-links {
    color: #c5c6c7;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-width: 2px;

  }
  .nav-links-mobile{
      display: none;
  }

  .nav-links:hover {
    color:rgb(102, 252, 241);
    border:2px solid rgb(102, 252, 241);
    border-radius: 2px;
    transition: all 0.2s ease-out;
  }

  .fa-bars, .fa-times{
    color:rgb(102, 252, 241);
    cursor: pointer;
  }

  .menu-icon{
      display: none;
  }

@media (max-width: 960px){
    .navbar{
        position: relative;
    }
    .nav-menu{
        display: flex;
        margin:0;
        padding: 0;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top:80px;
        left:-100%;
        opacity: 1;
        transition:  all 0.5s ease;
        
    }

    .nav-menu.active{
        background: #0b0c10;
        left:0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links{
        text-align:  center;
        padding: 2rem 0 2rem 0;
        width:100%;
        display: table;

    }

    .nav-links:hover{
        border: 0;
    }

    .navbar-logo{
        position: absolute;
        top:0;
        left:0;

    }
    .menu-icon{
        display: block;
        position: absolute;
        top:0;
        right:0;
        margin: 10px 10px 0 0;
        transform: translate(-100,60%);
        font-size: 2.8rem;
        cursor:pointer;
    }
}

  