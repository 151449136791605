.hiddenInput {
    display: none;
}

.imageDropContainer {
    height: 100%;
    width: 100%;

}
.imageDropContainer:hover {
    cursor: pointer;
}
img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
