.cardContainer {
    display: grid;
    grid-template-rows: 45vh;
    grid-template-areas:
        "image"
        "title"
        "metadata";
    gap: 0.4rem;
    height: 100%;
    width: inherit;
}
.image {
    display: flex;
    flex-direction: column;
    border: 1px solid #999;
    grid-area: image;
}

.title {
    border: 1px solid #999;
    grid-area: title;
    text-align: center;
    height: 1.5em;
    overflow:hidden;
}
.title,
.metadata,
.image {
    background-color: #c5c6c7;
    box-shadow: inset 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    box-shadow: inset 0 5px 15px 0 rgba(0, 0, 0, 0.5);
}
.metadata {
    border: 1px solid #999;
    grid-area: metadata;
    display: grid;
    padding: 1rem;
}
.submitMetadata {
    align-self: center;
}
