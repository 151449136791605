:root{
  --light-background: rgb(234,231,220);
  --dark-background: rgba(31,40,51,1);
  --blue-highlight: rgb(102, 252, 241);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(136,165,173);
  background: radial-gradient(circle, rgba(136,165,173,1) 0%, rgba(5,20,38,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
